import React, { Component } from "react"
import Layout from "../components/layout"
import Clutch from "../components/clutch"
import { Link } from "gatsby"
import SocialMedia from "../components/social_media-header"
import {Helmet} from "react-helmet"
import Smile from "../images/cb/smile-icon.png"
import Thank1 from "../images/cb/thnk-1.jpg"
import Thank2 from "../images/cb/thnk-2.jpg"
import Thank3 from "../images/cb/thnk-3.jpg"

export default class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: ''
    }
  }

  componentDidMount() {
    this.setState({
      url: document.location.href
    });
  }

  render() {

    return (
      <Layout>
        <Helmet>
          <title>CueForGood: Magento Development, Website Design, Search Engine Optimization and Conversion Rate Optimization for eCommerce Stores | Digital Design and Marketing Agency</title>
          <meta name="description"
            content="CueForGood has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
          <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no" />
          <link rel="canonical" href={this.state.url} />
          <meta property="og:site_name" content="CueForGood" />
          <meta property="fb:app_id" content="289086684439915" />
          <meta property="og:url" content={this.state.url} />
          <meta property="og:title"
            content="CueForGood: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing." />
          <meta property="og:description"
            content="CueForGood has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
          <meta property="og:image" content="https://www.cueforgood.com/images/cb.jpg" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:description"
            content="CueForGood has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
          <meta name="twitter:title"
            content="CueForGood: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing." />
          <meta name="twitter:site" content="@CueForGood" />
          <meta name="twitter:image" content="https://www.cueforgood.com/images/cb.jpg" />
        </Helmet>
        <section className="ser_sec_row thankyou-page thanks-new">
          <div className="container">

            <div className="upper-section">
              <img src={Smile} alt="Smile icon" className="img-responsive" />
              <h2 className="heading_main">Thank you for providing the details.</h2>
              <p>Your inquiry has been received, and either Ravi or Pancham from our sales team will promptly respond via email.</p>
              <p className="bold">In the meantime, please feel free to explore the following:</p>
            </div>

            <div className="three-blocks">
              <div className="block-thnk">
                <img src={Thank1} alt="Our Team" className="img-responsive" />
                <h3 className="heading_main">Our Kick-a#s Team</h3>
                <Link to="/team">Read More</Link>
              </div>
              <div className="block-thnk">
                <img src={Thank2} alt="Case Studies" className="img-responsive" />
                <h3 className="heading_main">Our Work</h3>
                <Link to="/work/">Read More</Link>
              </div>
              <div className="block-thnk">
                <img src={Thank3} alt="Our Blog" className="img-responsive" />
                <h3 className="heading_main">Our New Office</h3>
                <a href="https://www.cueforgood.com/blog/welcome-to-a-newer-cueblocks/" target="_blank">Read More</a>
              </div>
            </div>

          </div>
        </section>

      </Layout>
    )

  }
}

